import React, {useEffect, useState} from "react"
import BreadCrum from "../../../../../components/Breadcrumb"
import Layout from "../../../../../components/Layout"
import {useAxiosClient} from "../../../../../service/useAxiosClient"
import News from "../../../../../components/News/NewsItem"
import Hero from "../../../../../components/Hero"
import New from "../../../../../components/New"
import TitlePage from "../../../../../components/TitlePage"
import {navigate} from "gatsby"
import TopicalityMenu from "../../../../../components/TopicalityPage/TopicalityMenu"

const OpenTopicalityPage = props => {
	const {pageContext = {}, params = {}, location} = props
	const id = pageContext.id || params.id
	const [news, setNews] = useState([])
	const [newsList, setNewsList] = useState([])
	const [menuList, setMenuList] = useState([])

	const [, triggerRequest] = useAxiosClient(
		{},
		{
			manual: true,
		}
	)

	const [{data: dataMenu}] = useAxiosClient({
		url: "/publications/categories/news/",
	})

	const [{data}] = useAxiosClient({
		url: `/publications/${id}/new/`,
	})

	useEffect(() => {
		if (dataMenu && dataMenu.length) {
			setMenuList(dataMenu)
		}
	}, [dataMenu])

	const path = [
		{label: "Inicio", to: "/"},
		{
			label: news?.category?.title,
			to: `/actualidad/${news?.category?.pk}/${news?.category?.slug}`,
			state: {filters: {category: news?.category?.pk}},
		},
		{label: news?.title},
	]

	const [{data: newsListFetched}, newsListTrigger] = useAxiosClient(
		{
			url: `/publications/news/`,
			method: "GET",
		},
		{manual: true}
	)

	useEffect(() => {
		triggerRequest({
			url: `/publications/${id}/new/`,
			method: "get",
		})
			.then((response) => {
				const data = response.data
				if (data && data.length) {
					setNews(data[0])
					newsListTrigger({
						params: {
							page: 1,
							nitems: 6,
							noid: id,
							category: data[0].category.pk,
						},
					})
				} else {
					window.location.replace("/")
				}
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (newsListFetched) {
			setNewsList(newsListFetched?.items)
		}
	}, [newsListFetched])

	const getRoute = data =>
		navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)

	const renderNews = () => {
		return (
			newsList &&
			newsList.map(item => (
				<News
					text={item.title}
					date={item.publication_date}
					image={item.block1_image || "/imgs/news-logo.jpg"}
					className="col-12 col-sm-6 small"
					link={`/actualidad/${item.category.pk}/${item.category.slug}/${item.id}/${item.slug}`}
					id={item?.id || item?.pk}
				/>
			))
		)
	}

	const renderNew = () => {
		if (!news) return "Loading..."
		return (
			<New data={news} withSocialBtns>
				<span className="ActivityTab-newsRelated">Otras noticias:</span>
				<div className="row">{renderNews()}</div>
			</New>
		)
	}

	return (
		<Layout>
			<TitlePage
				title={pageContext.title}
				description={pageContext.description}
				image={pageContext.image}
				url={location.pathname}
			/>
			<Hero image="/imgs/actualidad-banner.jpg" text="Noticias EFPA"/>
			<div className="container950">
				<div className="ActivityTab">
					<BreadCrum path={path}/>
					<div className="row efpa-row">
						<div className="col-12 col-md-3 Topicality-padding-col">
							<TopicalityMenu
								list={menuList}
								showTitle={false}
								initialCategory={news?.category?.pk}
								getCategory={getRoute}
								useAllData
							/>
						</div>
						<div className="col-12 col-md-9 Topicality-col-9">
							{renderNew()}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export default OpenTopicalityPage
