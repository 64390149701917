import React from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"
import { Fragment } from "react"

export default function NewsItem(props) {
  const {
    className,
    link,
    image,
    title,
    text,
    date,
    small,
    id,
    category,
  } = props
  return (
    <article className={`NewsItem ${className} ${small}`}>
      <Link state={{ id: id }} className="NewsItem-link" to={link}>
        <figure className="NewsItem-image">
          <img src={image} alt="" />
        </figure>
        <div className="NewsItem-content">
          <span className="NewsItem-date">
            {date}
            {category && (
              <Fragment>
                {" - "}
                <Link
                  to={`/actualidad/${category.pk}/${category.slug}`}
                  state={{ filters: { category: category.pk } }}
                >
                  {category.title}
                </Link>
              </Fragment>
            )}
          </span>
          <span className="NewsItem-title">{title}</span>
          <span className="NewsItem-text">{text}</span>
        </div>
      </Link>
    </article>
  )
}

NewsItem.propTypes = {
  className: Proptypes.string,
  link: Proptypes.string,
  image: Proptypes.string,
  title: Proptypes.string,
  text: Proptypes.string,
  date: Proptypes.string,
  category: Proptypes.shape,
}

NewsItem.defaultProps = {
  className: "",
  link: "/",
  image: "",
  title: "",
  text: "",
  date: "",
  small: "",
}
