import React from "react"
import Brochure from "../Brochure"
import PropTypes from "prop-types"

export default function NewsTabBrochure(props) {
  const { files, title = "Información:" } = props
  return (
    <div className="NewsTab-borderTop">
      <span className="NewsTab-title">{title}</span>
      <div className="NewsTab-row">
        {Array.isArray(files) &&
          files.map(item => <Brochure text={item.title} link={item.file} blank />)}
      </div>
    </div>
  )
}

NewsTabBrochure.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, file: PropTypes.string })
  ),
  title: PropTypes.string,
}
