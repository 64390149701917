import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Breadcrumb from "../../components/Breadcrumb"
import Searcher from "../../components/Searcher"
import TitleBlock from "../../components/TitleBlock"
import NewsItem from "../../components/News/NewsItem"
import Gallery from "../../components/Gallery"
import { useAxiosClient } from "../../service/useAxiosClient"
import { navigate } from "gatsby"
import TitlePage from "../../components/TitlePage"
import TopicalityMenu from "../../components/TopicalityPage/TopicalityMenu"
import { searchT } from "../../commons/commons"

export default function Topicality({ location }) {
  const path = [{ label: "Inicio", to: "/" }, { label: "Actualidad" }]

  const [menuList, setMenuList] = useState([])
  const [featuredNews, setFeaturedNews] = useState({})
  const [lastNews, setLastNews] = useState([])
  const [searchParams] = useState({})

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })

  useEffect(() => {
    if (dataMenu && dataMenu.length) {
      setMenuList(dataMenu)
    }
  }, [dataMenu])

  useEffect(() => {
    getHomeNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [{ data: dataLastNews }, getHomeNews] = useAxiosClient(
    {
      url: "/publications/home/",
      method: "GET",
    },
    { manual: true }
  )

  useEffect(() => {
    if (dataLastNews) {
      setLastNews(dataLastNews)
      if (dataLastNews.featured) setFeaturedNews(dataLastNews.featured)
    }
  }, [dataLastNews])

  const getCategory = data => {
    navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)
  }

  const [, triggerSearch] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const handleSearchBar = filters => {
    navigate(`/actualidad/buscador-actualidad/?${searchT(filters)}`, {
      state: { filters: filters },
    })
  }

  useEffect(() => {
    const fetchNews = async () => {
      if (featuredNews) {
        const news = await triggerSearch({
          url: `/publications/search/?${searchT()}`,
          method: "GET",
          params: { noid: featuredNews.pk },
        })
        setLastNews(news?.data.items)
      }
    }
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const renderNews = () => {
    return (
      lastNews &&
      lastNews.last_news &&
      lastNews.last_news.map(item => {
        return (
          <div className="col-12 col-md-6 p-0">
            <NewsItem
              small="small"
              image={item.block1_image || "/imgs/news-logo.jpg"}
              date={item.publication_date}
              category={item.category}
              title={item.title}
              link={`/actualidad/${item.category.pk}/${item.category.slug}/${item.id}/${item.slug}`}
              id={item.id}
            />
          </div>
        )
      })
    )
  }

  const renderArticle = () => {
    return (
      lastNews &&
      lastNews.last_articles &&
      lastNews.last_articles.map(item => {
        return (
          <div className="col-12 col-md-6 p-0">
            <NewsItem
              small="small"
              image={item.block1_image || "/imgs/news-logo.jpg"}
              date={item.publication_date}
              category={item.category}
              title={item.title}
              link={`/actualidad/${item.category.pk}/${item.category.slug}/${item.id}/${item.slug}`}
              id={item.id}
            />
          </div>
        )
      })
    )
  }

  const renderBlockGalleries = () => {
    if (!lastNews.galleries) {
      return ""
    }

    return (
      <div className="Topicality-content">
        <span className="Topicality-subtitle">Galerías de imágenes:</span>
        <div className="row efpa-row">{renderGallery()}</div>
      </div>
    )
  }

  const renderGallery = () => {
    return (
      lastNews &&
      lastNews.galleries &&
      lastNews.galleries.map(item => {
        return (
          <div className="col-12 col-md-3 p-0">
            <Gallery
              image={item?.image}
              text={item?.title}
              quantity={item?.num_images}
              link={item?.link}
            />
          </div>
        )
      })
    )
  }

  return (
    <Layout>
      <TitlePage
        title="Descubre las últimas noticias de EFPA España"
        url={location.pathname}
      />
      <Hero image="/imgs/actualidad-banner.jpg" text="Actualidad" />
      <div className="container950">
        <Breadcrumb className="Topicality-breadcrumb" path={path} />
        <Searcher onChangeFilter={handleSearchBar} selectCategory={menuList} />
        <div className="row efpa-row">
          <div className="col-12 col-lg-3 Topicality-padding-col">
            <TopicalityMenu
              list={menuList}
              showTitle={false}
              getCategory={getCategory}
              useAllData
            />
          </div>
          <div className="col-12 col-lg-9 Topicality-col-9">
            <TitleBlock
              icon="far fa-newspaper"
              title="Noticias relativas al asesoramiento financiero"
            />
            <NewsItem
              image={featuredNews.block1_image || "/imgs/news-logo.jpg"}
              className="Topicality-news"
              category={featuredNews.category}
              date={featuredNews?.publication_date}
              text={featuredNews?.summary}
              title={featuredNews?.title}
              link={
                featuredNews.category &&
                `/actualidad/${featuredNews.category.pk}/${featuredNews.category.slug}/${featuredNews.id}/${featuredNews.slug}`
              }
              id={featuredNews.id}
            />
            <div className="Topicality-content">
              <span className="Topicality-subtitle">Noticias:</span>
              <div className="row efpa-row">{renderNews()}</div>
            </div>
            <div className="Topicality-content">
              <span className="Topicality-subtitle">Artículos:</span>
              <div className="row efpa-row">{renderArticle()}</div>
            </div>
            {renderBlockGalleries()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
