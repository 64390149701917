import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import UseEventListener from "../../service/useEventListener"
import { useAxiosClient } from "../../service/useAxiosClient"

const VideoLightBox = props => {
  const { isOpen, toggle, videos = [], manual, activeIndex } = props
  const [photoIndex, setPhotoIndex] = useState(0)

  const [{ data: videoDetails }] = useAxiosClient({
    url: `/videos/${videos[photoIndex].pk}/web/`,
    method: "GET",
  })

  console.log(`videoDetails`, videoDetails)

  useEffect(() => {
    if (manual) setPhotoIndex(activeIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const onNavigate = e => {
    if (e.key === "ArrowRight" || e.code === "ArrowRight") {
      setPhotoIndex((photoIndex + 1) % videos.length)
    }
    if (e.key === "ArrowLeft" || e.code === "ArrowLeft") {
      setPhotoIndex((photoIndex + videos.length - 1) % videos.length)
    }
  }

  UseEventListener(window, "keydown", onNavigate)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="VideoLightBox-content"
      backdropClassName="VideoLightBox-modalBackground"
      centered
      scrollable
    >
      {videoDetails && (
        <div dangerouslySetInnerHTML={{ __html: videoDetails.embed_code }} />
      )}
    </Modal>
  )
}

VideoLightBox.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  manual: PropTypes.bool,
  activeIndex: PropTypes.number,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      embed: PropTypes.string,
    })
  ).isRequired,
}

export default VideoLightBox
