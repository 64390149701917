import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { useAxiosClient } from "../service/useAxiosClient"

const NotFoundPage = props => {
  const prevPath = typeof window !== "undefined" ? window.previousPath : "";
  const [{ data }] = useAxiosClient({
    url: `/redirections/redirect/`,
    method: "POST",
    data: {
      seo: prevPath || props.location?.pathname,
    },
  })

  useEffect(() => {
    if (data && data.url) navigate(data.url)
  }, [data])

  return (
    <img src="/image/loading.gif" style={{ maxWidth: "100%" }} alt="loading gif" />
  )
}

NotFoundPage.propTypes = {}

export default NotFoundPage
