import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
import SocialButton from "./index"

const SocialButtons = ({ title }) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") setUrl(window.location.href);
  }, [])

  return (
    <div className="d-flex my-2">
      <SocialButton
        icon="fab fa-facebook-f"
        backgroundColor="#42519d"
        link={`http://www.facebook.com/sharer/sharer.php?u=${url}`}
        className="w-100 mr-1"
      />
      <SocialButton
        icon="fa-brands fa-x-twitter"
        backgroundColor="#000000"
        link={`https://twitter.com/intent/tweet?text=${title || ''}&url=${url}`}
        className="w-100 ml-1"
      />
    </div>
  )
}

SocialButtons.propTypes = {}

export default SocialButtons
