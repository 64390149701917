import React from "react"
import { Router } from "@reach/router"
import PresentPage from "./[ctypk]/[category]"
import OpenTopicalityPage from "./[ctypk]/[category]/[id]/[slug]"
import Topicality from "."
import Galleries from "./galerias"
import GalleryFile from "./galerias/[id]/[slug]"
import Videos from "./videos"
import VideoTab from "./videos/[id]/[slug]"
import SearchNews from "./buscador-actualidad"
import NotFoundPage from "../404"

const App = () => {
  return (
    <Router basepath="/actualidad">
      <PresentPage path="/:ctypk/:cateogry" />
      <OpenTopicalityPage path="/:ctypk/:cateogry/:id/:slug" />
      <VideoTab path="/videos/:id/:slug" />
      <Videos path="/videos" />
      <GalleryFile path="/galerias/:id/:slug" />
      <Galleries path="/galerias" />
      <SearchNews path="/buscador-actualidad" />
      <NotFoundPage path="/:ctypk" />
      <Topicality path="/" />
    </Router>
  )
}
export default App
