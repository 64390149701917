import React, { useEffect, useState } from "react"
import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import Breadcrumb from "../../../components/Breadcrumb"
import Searcher from "../../../components/Searcher"
import TitleBlock from "../../../components/TitleBlock"
import Pagination from "../../../components/Pagination"
import Gallery from "../../../components/Gallery"
import { navigate } from "gatsby"
import { useAxiosClient } from "../../../service/useAxiosClient"
import TopicalityMenu from "../../../components/TopicalityPage/TopicalityMenu"

export default function Galleries() {
  const [menuList, setMenuList] = useState([])
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Actualidad", to: "/actualidad" },
    { label: "Galerías de imágenes" },
  ]
  const [currentPage, setCurrentPage] = useState(1)

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })
  const [{ data: galleriesData = {} }] = useAxiosClient({
    url: "/galleries/web/",
    method: "GET",
    params: {
      page: currentPage,
    },
  })

  useEffect(() => {
    if (dataMenu && dataMenu.length) {
      setMenuList(dataMenu)
    }
  }, [dataMenu])

  const getPage = page => {
    setCurrentPage(page)
  }

  const renderImage = () => {
    return (
      Array.isArray(galleriesData.items) &&
      galleriesData.items.map(item => {
        return (
          <div className="col-12 col-md-4 p-0">
            <Gallery
              image={item.image}
              text={item.title}
              quantity={item.num_images}
              link={`${item.pk}/${item.slug}`}
            />
          </div>
        )
      })
    )
  }

  const getRoute = data =>
    navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)

  return (
    <Layout>
      <Hero image="/imgs/actualidad-banner.jpg" text="Galerías de imágenes" />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <TopicalityMenu
              list={menuList}
              showTitle={false}
              initialCategory={-2}
              getCategory={getRoute}
              useAllData
            />
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <TitleBlock
              icon="fas fa-pen-square"
              title="Galerías de imágenes de EFPA España"
            />
            <Pagination
              currentPage={currentPage}
              totalPages={galleriesData?.total_pages || 1}
              getPage={getPage}
            />
            <div className="Galleries-content">
              <div className="row efpa-row">{renderImage()}</div>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={galleriesData?.total_pages || 1}
              getPage={getPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
