import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import VideoLightBox from "./VideoLightBox"

const VideoWithLightBox = props => {
  const {
    className,
    onClick,
    multiple,
    btnClassName,
    src = "",
    alt = "",
    videos,
    manual,
    ...otherProps
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [currentImg, setCurrentImg] = useState(0)

  const toggle = () => setIsOpen(prev => !prev)

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick && typeof onClick === "function") onClick(e)
    toggle()
  }

  const handleSelectImg = imgIdx => e => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick && typeof onClick === "function") onClick(e)
    setCurrentImg(imgIdx)
    toggle()
  }

  console.log(`videos`, videos)

  return (
    <Fragment>
      {!multiple ? (
        <a
          onClick={handleClick}
          href="#img-preview"
          className={`NewsTab-videos ${btnClassName} ${className}`}
          role="button"
        >
          <img {...otherProps} alt={alt} src={src} />
          <i className="fas fa-caret-right"></i>
        </a>
      ) : (
        Array.isArray(videos) &&
        videos.map(({ src: itemSrc, alt: itemAlt, ...item }, i) => (
          <Fragment>
            <a
              onClick={handleSelectImg(i)}
              href="#img-preview"
              className={`NewsTab-videos ${btnClassName} ${className}`}
              role="button"
              key={`ImgWithLightBox-${i}-thumb`}
            >
              <img {...item} alt={itemAlt} src={itemSrc} />
              <i className="fas fa-caret-right"></i>
              {itemAlt ? (<p className="Videos-text">{itemAlt}</p>) : <Fragment />}
            </a>
          </Fragment>
        ))
      )}
      {isOpen && (
        <VideoLightBox
          toggle={toggle}
          isOpen
          videos={multiple ? videos : [props, props]}
          manual={multiple}
          activeIndex={currentImg}
        />
      )}
    </Fragment>
  )
}

VideoWithLightBox.propTypes = {
  style: PropTypes.shape,
  className: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      style: PropTypes.shape,
      className: PropTypes.string,
      alt: PropTypes.string,
      src: PropTypes.string,
    })
  ),
}

export default VideoWithLightBox
