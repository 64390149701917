import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export default function NewsTabActivities(props) {
  const { activities, title = "Actividades relacionadas:" } = props
  const renderActivities = () => {
    if (!Array.isArray(activities)) return
    return activities.map(item => {
      return (
        <div className="NewsTabActivities">
          <span className="NewsTabActivities-date">
            {`${item.calendar} - `}
            {item.category && (
              <Link
                className="NewsTabActivities-category"
                to={`/servicios-y-actividades/${item.category.categoryid || item.category.pk}/${item.category.slug}`}
              >
                {item.category.title}
              </Link>
            )}
          </span>
          <Link
            className="NewsTabActivities-link"
            to={`/servicios-y-actividades/${item.category.categoryid || item.category.pk}/${item.category.slug}/${item.id}/${item.slug}`}
          >
            {item.title}
          </Link>
        </div>
      )
    })
  }

  return (
    <div className="NewsTab-borderTop">
      <span className="NewsTab-title mb-4">{title}</span>
      {renderActivities()}
    </div>
  )
}

NewsTabActivities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      calendar: PropTypes.string,
      slug: PropTypes.string,
      pk: PropTypes.number,
      id: PropTypes.number,
    })
  ),
  title: PropTypes.string,
}
