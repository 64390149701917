import React from "react"
import VideoWithLightBox from "../VideoWithLightBox"

export default function NewsTabVideos(props) {
  const { listVideo, className } = props

  const renderVideos = () => {
    if (!listVideo) return null

    return (
      listVideo && (
        <VideoWithLightBox
          videos={listVideo.map(item => ({
            src: item.list,
            alt: item.title,
            className: "NewsTabImage-img",
            thumb: item.thumb || "/imgs/news-logo.jpg",
            ...item,
          }))}
          btnClassName="NewsTabImage-btn"
          multiple
        />
      )
    )
  }
  return (
    <div className={`NewsTab-borderTop ${className}`}>
      <span className="NewsTab-title">Videos:</span>
      <div className="NewsTab-row d-flex flex-wrap">{renderVideos()}</div>
    </div>
  )
}
