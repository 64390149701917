import React, { useEffect, useState } from "react"
import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import TitleBlock from "../../../components/TitleBlock"
import VideoItem from "../../../components/Gallery"
import Pagination from "../../../components/Pagination"
import { useAxiosClient } from "../../../service/useAxiosClient"
import TitlePage from "../../../components/TitlePage"
import Breadcrumb from "../../../components/Breadcrumb"

export default function Videos({ location }) {
  const [videos, setVideos] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const path = [{ label: "Inicio", to: "/" }, { label: "Canal EFPA" }]

  const [{ data: dataVideos }] = useAxiosClient({
    url: `/videos/web/?page=${currentPage}&nitems=24`,
    method: "GET",
  })

  useEffect(() => {
    if (dataVideos) {
      setVideos(dataVideos.items)
      setTotalPages(dataVideos?.total_pages)
    }
  }, [dataVideos])

  const getPage = page => {
    setCurrentPage(page)
  }

  const renderVideos = () => {
    return (
      videos.length &&
      videos.map(item => {
        return (
          <VideoItem
            type="video"
            image={item?.cover || "/imgs/default-image.jpg"}
            text={item?.title}
            link={`/actualidad/videos/${item?.pk}/${item?.slug}`}
            id={item?.pk}
          />
        )
      })
    )
  }

  return (
    <Layout>
      <TitlePage title="Canal EFPA" url={location.pathname} />
      <Hero image="/imgs/channel-hero.jpg" text="Canal EFPA" />
      <div className="container950">
        <div className="Videos">
          <Breadcrumb path={path} />
          <TitleBlock title="Canal EFPA" icon="fab fa-youtube" />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            getPage={getPage}
          />
          <div className="row efpa-row justify-content-between">
            {renderVideos()}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            getPage={getPage}
          />
        </div>
      </div>
    </Layout>
  )
}
