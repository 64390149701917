export const capitalizeFirstLetter = (str = "") =>
  str.length ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : ""

export const searchT = filters => {
  const params = Object.entries(filters || {})
    .reduce((acc, [key, value]) => {
      if (key !== "slug") {
        return [...acc, `${key}=${value}`]
      }
      return acc
    }, [])
    .join("&")

  return params
}

export function replaceOembedWithIframe(inputText) {
  // Expresión regular para buscar <oembed> tags con una URL dentro
  const regex = /<oembed\s+url="(.*?)"><\/oembed>/g

  // Reemplaza cada coincidencia de la expresión regular con un <iframe> tag
  const outputText = inputText.replace(regex, function (match, url) {
    // Expresión regular para extraer el ID del video de la URL de YouTube
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    // Intenta hacer coincidir la URL con la expresión regular de YouTube
    const youtubeMatch = url.match(youtubeRegex)
    // Si hay una coincidencia, extrae el ID del video
    if (youtubeMatch && youtubeMatch[1]) {
      const videoId = youtubeMatch[1]
      const embedUrl = `https://www.youtube.com/embed/${videoId}`
      return `<iframe src="${embedUrl}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`
    } else {
      // Si no hay una coincidencia, devuelve el match original
      return match
    }
  })
  return outputText
}
