import React, { Fragment, useEffect, useState } from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Breadcrumb from "../../components/Breadcrumb"
import Searcher from "../../components/Searcher"
import { useAxiosClient } from "../../service/useAxiosClient"
import TitleBlock from "../../components/TitleBlock"
import NewsItem from "../../components/News/NewsItem"
import { navigate } from "gatsby"
import Pagination from "../../components/Pagination"
import TopicalityMenu from "../../components/TopicalityPage/TopicalityMenu"
import { searchT } from "../../commons/commons"
import * as queryString from "query-string"

export default function SearchNews(props) {
  const filters = queryString.parse(props.location.search)
  const cty = parseInt(filters.category)
  const [menuList, setMenuList] = useState([])
  const [, setCategory] = useState(3)
  const [newsFiltered, setNewsFiltered] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const getPage = numPage => setPage(numPage)

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })

  const [{ data: dataFilters }] = useAxiosClient({
    url: `/publications/search/`,
    method: "GET",
    params: {
      page,
      ...filters,
    },
  })

  useEffect(() => {
    if (dataMenu && dataMenu.length) {
      setMenuList(dataMenu)
    }
  }, [dataMenu])

  const getCategory = data => {
    setCategory(data.pk)
    navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)
  }

  useEffect(() => {
    const fetchNews = () => {
      setNewsFiltered(dataFilters?.items)
      setTotalPages(dataFilters?.total_pages)
    }

    fetchNews()
  }, [dataFilters])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Actualidad", to: "/actualidad" },
    { label: "Buscador actualidad" },
  ]

  const renderNews = () => {
    return (
      newsFiltered &&
      newsFiltered.map(item => {
        return (
          <div className="col-12 col-md-6 p-0">
            <NewsItem
              small="small"
              image={item?.block1_image || "/imgs/news-logo.jpg"}
              date={item?.publication_date}
              title={item?.title}
              link={`/actualidad/${item.category.pk}/${item.category.slug}/${item.pk}/${item.slug}`}
              id={item.id}
            />
          </div>
        )
      })
    )
  }

  const handleSearchBar = filters => {
    setPage(1)
    navigate(`/actualidad/buscador-actualidad/?${searchT(filters)}`, {
      state: { filters: filters },
    })
  }

  const renderPagination = () => {
    if (totalPages <= 1) {
      return (
        <div className="BibliographicRecommendation-wrapper">
          <div className="row efpa-row">{renderNews()}</div>
        </div>
      )
    }

    return (
      <Fragment>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          getPage={getPage}
        />
        <div className="BibliographicRecommendation-wrapper">
          <div className="row efpa-row">{renderNews()}</div>
        </div>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          getPage={getPage}
        />
      </Fragment>
    )
  }

  return (
    <Layout>
      <Hero image="/imgs/actualidad-banner.jpg" text="Buscador" />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher
          onChangeFilter={handleSearchBar}
          selectCategory={menuList}
          defaultValues={filters}
        />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <TopicalityMenu
              list={menuList}
              getCategory={getCategory}
              initialCategory={cty}
              useAllData
            />
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <TitleBlock icon="far fa-newspaper" title="Buscador actualidad" />
            {renderPagination()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
