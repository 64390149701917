import React from "react"
import Proptypes from "prop-types"

export default function NewsTabHead(props) {
  const { className, date, title, subtitle } = props
  return (
    <div className={`NewsTabHead ${className}`}>
      <span className="NewsTabHead-date">{date}</span>
      <span className="NewsTabHead-title">{title}</span>
      <span className="NewsTabHead-subttile">{subtitle}</span>
    </div>
  )
}

NewsTabHead.propTypes = {
  className: Proptypes.string,
  date: Proptypes.string,
  title: Proptypes.string,
  subtitle: Proptypes.string,
}

NewsTabHead.defaultProps = {
  className: "",
  date: "",
  title: "",
  subtitle: "",
}
