import React, { useEffect, useState } from "react"
import Layout from "../../../../components/Layout"
import Hero from "../../../../components/Hero"
import { useAxiosClient } from "../../../../service/useAxiosClient"
import parse from "react-html-parser"
import NewsTabActivities from "../../../../components/NewsTab/NewsTabActivities"
import Pagination from "../../../../components/Pagination"
import VideoItem from "../../../../components/Gallery"
import { Fragment } from "react"
import SocialButton from "../../../../components/SocialButton"
import TitlePage from "../../../../components/TitlePage"
import Breadcrumb from "../../../../components/Breadcrumb"

export default function VideoTab({ params = {}, pageContext = {}, location }) {
  const id = pageContext.id || params.id
  const [videoTab, setVideoTab] = useState({})
  const [videos, setVideos] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Canal EFPA", to: "/actualidad/videos" },
    {
      label: videoTab?.title,
    },
  ]

  const [{ data: dataVideos }] = useAxiosClient({
    url: `/videos/web/?page=${currentPage}&nitems=24`,
    method: "GET",
  })

  useEffect(() => {
    if (dataVideos) {
      setVideos(dataVideos.items)
      setTotalPages(dataVideos?.total_pages)
    }
  }, [dataVideos])

  const getPage = page => {
    setCurrentPage(page)
  }

  const [{ data: dataVideosTab }] = useAxiosClient({
    url: `/videos/${id}/web/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataVideosTab) {
      setVideoTab(dataVideosTab)
    }
  }, [dataVideosTab])

  const renderVideos = () => {
    return (
      videos.length &&
      videos.map(item => {
        if (item.pk === id) {
          return (
            <VideoItem
              type="video"
              image={item?.cover || "/imgs/default-image.jpg"}
              text={item?.title}
              link={`/actualidad/videos/${item.pk}/${item.slug}`}
              id={item?.pk}
              selected={true}
            />
          )
        }
        return (
          <VideoItem
            type="video"
            image={item?.cover || "/imgs/default-image.jpg"}
            text={item?.title}
            link={`/actualidad/videos/${item.pk}/${item?.slug}`}
            id={item?.pk}
            selected={false}
          />
        )
      })
    )
  }

  const renderActivities = () => {
    if (Array.isArray(videoTab?.activities) && videoTab.activities.length) {
      return (
        <NewsTabActivities
          title="Actividades relacionadas:"
          list={videoTab?.activities}
          basePath={`/servicios-y-actividades`}
        />
      )
    }

    return <Fragment />
  }

  const renderPublications = () => {
    if (Array.isArray(videoTab?.publications) && videoTab.publications.length) {
      return (
        <NewsTabActivities
          title="Noticias relacionadas:"
          list={videoTab?.publications}
          basePath={`/actualidad`}
        />
      )
    }
    return <Fragment />
  }

  return (
    <Layout>
      <TitlePage
        title={pageContext.title}
        description={pageContext.description}
        image={pageContext.image}
        url={location.pathname}
      />
      <Hero image="/imgs/actualidad-banner.jpg" text="Canal EFPA" />
      <div className="container950">
        <Breadcrumb path={path} />
        <div className="VideoTab">
          <span className="VideoTab-title">{videoTab?.title}</span>
          <div className="VideoTab-embedContent">
            {parse(videoTab?.embed_code)}
          </div>
          <div className="GalleryFile-social mt-3">
            <SocialButton
              icon="fab fa-facebook-f"
              backgroundColor="#42519d"
              link="/"
              className="w-100 mr-1"
            />
            <SocialButton
              icon="fa-brands fa-x-twitter"
              backgroundColor="#000000"
              link="/"
              className="w-100 ml-1"
            />
          </div>
          {renderActivities()}
          {renderPublications()}
          <div className="VideoTab-videos">
            <span className="VideoTab-videos-title">Canal EFPA:</span>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              getPage={getPage}
            />
            <div className="row efpa-row justify-content-between">
              {renderVideos()}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              getPage={getPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
