import React, { useEffect, useState } from "react"
import Layout from "../../../../components/Layout"
import Hero from "../../../../components/Hero"
import Breadcrumb from "../../../../components/Breadcrumb"
import Searcher from "../../../../components/Searcher"
import NewsItem from "../../../../components/News/NewsItem"
import TitleBlock from "../../../../components/TitleBlock"
import Pagination from "../../../../components/Pagination"
import { useAxiosClient } from "../../../../service/useAxiosClient"
import { navigate } from "gatsby"
import TitlePage from "../../../../components/TitlePage"
import TopicalityMenu from "../../../../components/TopicalityPage/TopicalityMenu"
import { searchT } from "../../../../commons/commons"

export default function PresentPage({
  pageContext = {},
  params = {},
  location,
}) {
  const ctypk = +pageContext.ctypk || +params.ctypk
  const filters = { category: ctypk }
  const [currentPage, setCurrentPage] = useState()
  const [featuredNews, setFeaturedNews] = useState({})
  const [newsList, setNewsList] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [menuList, setMenuList] = useState([])
  const [, setCategory] = useState(1)
  const [title, setTitle] = useState("")

  const [{ data: dataMenu }] = useAxiosClient({
    url: "/publications/categories/news/",
    method: "GET",
  })

  const [, triggerSearch] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const [{ data }] = useAxiosClient({
    url: `/publications/news-last-featured/`,
    method: "GET",
    params: { category: +ctypk },
  })

  useEffect(() => {
    if (Array.isArray(dataMenu)) {
      setMenuList(dataMenu)
      setTitle(() => {
        const categoryInfo = dataMenu?.find(
          item => item?.pk === filters?.category
        )
        return categoryInfo?.title
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMenu, ctypk])

  useEffect(() => {
    if (Array.isArray(data)) {
      const firstFeauture = data[0]
      setFeaturedNews(firstFeauture)
      fetchNews({ noid: firstFeauture?.pk })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (currentPage) fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const fetchNews = async (params = {}) => {
    await triggerSearch({
      url: `/publications/search/`,
      method: "GET",
      params: {
        noid: featuredNews?.pk,
        page: currentPage,
        category: +ctypk,
        ...params,
      },
    })
      .then(({ data: news }) => {
        if (!totalPages) setTotalPages(news.total_pages)
        setNewsList(news.items)
      })
      .catch(() =>
        triggerSearch({
          url: `/redirections/redirect/`,
          method: "POST",
          data: {
            seo: typeof window !== "undefined" ? window.location.pathname : "",
          },
        }).then(({ data }) => data && data.url && navigate(data.url))
      )
  }

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Actualidad", to: "/actualidad" },
    { label: "Noticias de EFPA" },
  ]

  const getPage = page => {
    setCurrentPage(page)
  }

  const renderNews = () => {
    return (
      newsList &&
      newsList.map(item => {
        return (
          <div className="col-12 col-md-6 p-0">
            <NewsItem
              small="small"
              image={item.block1_image || "/imgs/news-logo.jpg"}
              date={item?.publication_date}
              title={item?.title}
              link={`${item?.id}/${item?.slug}`}
              id={item?.id}
            />
          </div>
        )
      })
    )
  }

  const renderPrincipalNews = () => {
    if (typeof featuredNews === "object") {
      return (
        <NewsItem
          image={featuredNews.block1_image || "/imgs/news-logo.jpg"}
          className="Topicality-news"
          date={featuredNews?.publication_date}
          text={featuredNews?.summary}
          title={featuredNews?.title}
          link={`${featuredNews?.id}/${featuredNews?.slug}`}
          id={featuredNews.id}
        />
      )
    }
  }

  const handleSearchBar = filters => {
    navigate(`/actualidad/buscador-actualidad/?${searchT(filters)}`, {
      state: { filters: filters },
    })
  }

  const getRoute = data =>
    navigate(data.link ? data.link : `/actualidad/${data.pk}/${data.slug}`)

  const getCategory = data => {
    setCategory(data.pk)
    getRoute(data)
  }

  return (
    <Layout>
      <TitlePage title={title} url={location.pathname} />
      <Hero image="/imgs/actualidad-banner.jpg" text="Noticias EFPA" />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher onChangeFilter={handleSearchBar} selectCategory={menuList} />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <TopicalityMenu
              list={menuList}
              showTitle={false}
              initialCategory={filters.category || 0}
              getCategory={getCategory}
              useAllData
            />
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <TitleBlock icon="far fa-newspaper" title={title} />
            {renderPrincipalNews()}
            <Pagination
              currentPage={currentPage || 1}
              totalPages={totalPages}
              getPage={getPage}
            />
            <div className="Topicality-content">
              <span className="Topicality-subtitle">Noticias:</span>
              <div className="row efpa-row">{renderNews()}</div>
            </div>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={totalPages}
              getPage={getPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
