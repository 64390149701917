import React from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"
export default function Brochure(props) {
  const { className, text, link, blank } = props
  return (
    <div className={`Brochure ${className}`}>
      {blank ? (
        <a
          className="Brochure-link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="far fa-file"></i>
          {text}
        </a>
      ) : (
        <Link to={link} className="Brochure-link">
          <i className="far fa-file"></i>
          {text}
        </Link>
      )}
    </div>
  )
}
Brochure.propTypes = {
  className: Proptypes.string,
  link: Proptypes.string,
  text: Proptypes.string,
  blank: Proptypes.bool,
}
Brochure.defaultProps = {
  className: "",
  link: "",
  text: "",
}
