import React, { useEffect, useState } from "react"
import BreadCrum from "../Breadcrumb"
import htmlParse from "react-html-parser"
import NewsTabVideos from "../NewsTab/NewsTabVideos"
import NewsTabBrochure from "../NewsTab/NewsTabBrochure"
import ImgWithLightBox from "../ImgWithLightBox"
import NewsTabActivities from "../NewsTab/NewsTabActivities"
import NewsTabHead from "../NewsTab/NewsTabHead"
import SocialButtons from "../SocialButton/SocialButtons"
import { useReplaceIframe } from "../../hooks/useReplaceIframe"
import CarouselEfpa from "../Carousel"

const New = props => {
  const [value, setValue] = useState(0)
  const { data: news = {}, path, withSocialBtns } = props
  const {replacedIframeDescription} = useReplaceIframe(news.description)
  const {replacedIframeDescription:replacedIframeDescription2} = useReplaceIframe(news.description_block2)
  
  const fromGalleryApiToDTO = (dataFromApi) => {
    return dataFromApi.map(item => ({
      alt: item.title,
      src: item.zoom,
      title: item.title
    }));
  };

  const renderEmbed = embedCode =>
    embedCode && (
      <div className="py-3" dangerouslySetInnerHTML={{ __html: embedCode }} />
    )
    const selectSlide = slide => {
      setValue(slide)
    }

  const renderFiles = () =>
    news?.files && <NewsTabBrochure files={news.files} />

  const renderGallery = () =>
    Array.isArray(news?.gallery) &&
    !!news.gallery.length && (
      // <NewsTabImage list={news.gallery} renderField="zoom" />
      <div style={{marginBottom:"2rem"}}>
        <CarouselEfpa
        className="GalleryFile-carousel overflow-hidden"
        // slides={images}
        thumbnails={fromGalleryApiToDTO(news.gallery)}
        onChange={selectSlide}
        value={value}
        itemWidth={700}
      >
          <ImgWithLightBox
                  images={fromGalleryApiToDTO(news.gallery)}
                  preview="selected"
                  selected={value}
                  onChange={selectSlide}
                  manual
                  multiple
                />
      </CarouselEfpa>
    </div>    
    )

  const renderVideos = () =>
    Array.isArray(news?.videos) &&
    !!news.videos.length && (
      <NewsTabVideos
        className="NewsTab-video withoutText"
        listVideo={news.videos}
      />
    )

  const renderActivities = () =>
    Array.isArray(news?.activities) &&
    !!news.activities.length && (
      <NewsTabActivities activities={news.activities} />
    )

  const renderImgs = imgField => {
    if (
      !news ||
      (news && !news[`print_image_${imgField}`]) ||
      !news[`${imgField}_image`]
    )
      return null
    return (
      <ImgWithLightBox
        className="ActivityTab-img"
        style={{
          float: news[`${imgField}_image_align`] === 2 ? "left" : "right",
        }}
        src={news[`${imgField}_image`]}
        alt={news.title}
        title={news.title}
      />
    )
  }

  const renderClippings = () =>
    news?.files_clipping && (
      <NewsTabBrochure files={news.files_clipping} title="Clipping" />
    )

  const renderSocialBtns = () =>
    withSocialBtns && <SocialButtons title={news?.title} />

  return (
    <div className="ActivityTab">
      {path && <BreadCrum path={path} />}
      <NewsTabHead
        date={news?.publication_date}
        title={news?.title}
        subtitle={news?.summary}
      />
      <div className="ActivityTab-content mb-3 d-inline-block">
        {renderImgs("block1")}
        {htmlParse(replacedIframeDescription)}
      </div>
      {news.gallery_position == 1 && renderGallery()}
      {renderEmbed(news?.embed_code)}
      <span className="ActivityTab-subtitle">{news?.title_block2}</span>
      <div className="ActivityTab-content mb-3 d-inline-block">
        {renderImgs("block2")}
        {htmlParse(replacedIframeDescription2)}
      </div>
      {news.gallery_position != 1 && renderGallery()}
      {renderSocialBtns()}
      {renderClippings()}
      {renderEmbed(news?.embed_code_block2)}
      {renderEmbed(news?.embed_code_footer)}
      {renderFiles()}
      <div className="d-block">        
        {renderVideos()}
      </div>
      {renderActivities()}
      {props.children}
    </div>
  )
}

export default New
