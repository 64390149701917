import { useState, useEffect } from "react"
import { replaceOembedWithIframe } from "../commons/commons"

export const useReplaceIframe = text => {
  const [replacedIframeDescription, setReplaceIframeDescription] = useState(
    null
  )

  useEffect(() => {
    if (text) {
      setReplaceIframeDescription(replaceOembedWithIframe(text))
    } else {
      setReplaceIframeDescription(null)
    }
  }, [text])

  return { replacedIframeDescription }
}
